  <template>
  <div class="detail">
    <div class="ltnav">
      <b-breadcrumb :items="navitems" class="container"></b-breadcrumb>
    </div>
    <div class="maintext container">
      <div class="title">
        <span>{{ title }}</span>
        <a href="">点击直接了解</a>
      </div>
      <div class="pic">
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div v-for="(it, index) in textArr" :key="index" class="content">
        <h3>{{ it.name }}</h3>
        <ul>
          <li
            v-for="item in textArr[index].text"
            :key="item.id"
            class="list-unstyled"
          >
            <p>
              {{ item }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      navitems: [
        {
          text: "我们业务",
          href: "#",
        },
        {
          text: "",
          href: "#",
        },
        {
          text: "",
          active: false,
        },
      ],
      textArr: [],
      textArr00X: [
        {
          id: 0,
          ptitle: "虚拟仿真方向",
          title: "中学虚拟仿真实验平台",
          content: [
            {
              name: "项目介绍",
              text: [
                "广东东田教育集团，是一家集教育音像产品研发与发行、在线教育平台与移动教育应用开发、个性化教育培训等业务于一体的教育集团。",
                "该项目与东田携手打造中学虚拟仿真实验平台，是为了初高中教师打造的物理、化学、生物实验操作与演示的DIY仿真实验工具。涵盖初高中八大教材版本的所需实验资源课件，自主研发了四大引擎，拥有千多个实验资源，百多种实验器材，可实现上万种实验操作与演示。",
              ],
            },
            {
              name: "应用行业",
              text: [
                "与东田教育携手将可以覆盖完中小学人教版所有知识点的教材实验，未来将有不断更新内容的建模需求、建成后可以自主研发新一代教材实验内容、向所有的K12在校教育提供内容接口，按照内容付费的方式规模化收益。",
              ],
            },
            {
              name: "应用场景",
              text: [
                "校园虚拟实验应用：满足在常规教室、平板教室、多媒体教室、微机教室等各种教学场景使用，提升实验课堂教学效率。",
                "教培机构虚拟实验应用：为机构提供线上、线下解决方案，提供平台对接和定制化解决方案。知识形象化、教学场景化，使课堂演示和知识讲解更加简单高效、生动趣味，增强课堂表现力和学生课堂参与感，提升教学效率和课程质量。提高习题讲解表现力，降低老师讲解难度。",
                "区县级平台对接整合应用：区级、县级平台本身已经有了足够多的教学资源，但缺乏的是像虚拟实验资源。通过产品整合方案，实现将虚拟仿真实验对接和授权到区级或县级平台，整合后，该区域内的师生就可以直接访问虚拟仿真实验资源。",
              ],
            },
          ],
        },
        {
          id: 1,
          ptitle: "虚拟仿真方向",
          title: "工业机器人智能制造实训",
          content: [
            {
              name: "需求背景",
              text: [
                "通过虚拟仿真技术，利用受限的实训环境，面向高校、高职、企业的智能制造、机器人、机电、自动化、工业 互联网专业集群的人才培养方案 ",
                "机器人制造厂商：需求机器人组装、销售、售后支持的技术和营销人才",
              ],
            },
            {
              name: "应用行业",
              text: [
                "机器人VR实训平台既可以实现对工种的习训也可以实现整个产线的习训，具有很强复 用性，同时有效降低教学事故，激发学生的钻研兴趣，为工业4.0培养高端专业人才。",
                "智能制造车间平台将为人才培育提供国内一流的实训环境与条件，可用于培养从事智能制造技术研究的应用型人才。",
                "带动高校高职客户的“智能制造、机电、自动化、机器人、工业互联网、设计、营销、法律、知识产权、会计、管理”等多专业面向“智能制造”专业集群展开全方位、对接实际产业需求的教学实训，进一步夯实制造业升级的人才基石。",
              ],
            },
            {
              name: "应用场景",
              text: [
                "线上线下混合教学智能制造实验&实训室主要分为三部分：工业机器人教学实训平台 （线下），工业机器人仿真实训平台（线上）、MES制造执行系统（线上/线下混合教学）。",
                "高校、高职客户可以按照需求分期采购，即第一批采购工业机器人VR实训平台，升级时购买MES制造执行系统。",
              ],
            },
          ],
        },
        {
          id: 2,
          ptitle: "虚拟仿真方向",
          title: "关节镜微创手术虚拟训练平台",
          content: [
            {
              name: "项目来源",
              text: [
                "广州市科技计划项目“虚拟关节镜手术训练环境及装置研发”（项目编号：2010J-D00341）",
                "国家自然科学基金项目“虚拟手术中病变器官的生理特性的表现方法研究”（项目编号：61300106）",
              ],
            },
            {
              name: "应用场景",
              text: [
                "由硬件交互装置和软件系统组成，具有实时、逼真的3D视觉效果和力反馈功能。系统提供了典型的案例模拟（如游离体摘除手术、半月板修复术等），并支持个性化患者的医学影像数据，可用于关节镜手术训练和术前预演。",
              ],
            },
            {
              name: "项目成果",
              text: [
                "发明专利：带力反馈的膝关节镜手术训练装置 ",
                "实用新型专利：一种六自由度力反馈手术训练的的装置",
              ],
            },
            {
              name: "核心技术",
              text: [
                "基于医学数据图像的高精度建模，以及不同数据来源的模型之间的融合技术，为病症诊断、虚拟手术训练、术前规划等提供基础。",
                "通过多传感器进行信息采集、处理和融合的方法，实现三维模型视觉-力觉的实时交互，解决虚拟手术训练中的力感知问题。",
                "虚拟训练平台通过虚拟训练装置与虚拟手术场景交互，能够适应不同手术案例，实现面向制定患者的制定手术预演。",
              ],
            },
          ],
        },
        {
          id: 3,
          ptitle: "虚拟仿真方向",
          title: "虚拟康复医疗训练系统",
          content: [
            {
              name: "项目来源",
              text: [
                "广东省科技计划项目（2017B020210009,2017-2019,100万）《具有VR感知的上肢康复设备的研发》",
                " 广州市产学研协同创新重大专项（201704020110,2017-2019,200万）《基于VR头盔虚拟环境的步态康复训练机器人的研发》",
              ],
            },
            {
              name: "应用场景",
              text: [
                "本课题研究将根据上肢和下肢的功能障碍程度，一方面提供不同级别的康复训练平台，实施上、下肢的精准康复，另一方面具有功能导向性的VR康复训练环境，提高患者康复训练的兴趣性。",
              ],
            },
            {
              name: "应用行业",
              text: [
                "该康复评价方法提出的客观、准确、最化的康复评价指标使治疗师和患者可以更直观、更准确地进行评估和训练，在未来可广泛运用于康复医疗中。",
              ],
            },
            {
              name: "核心技术",
              text: [
                "提出了一种基于关节角度的校准方法,可以对肌肉所产生的sEMG幅值进行修正。",
                "提出采用并行级联识别方法(PCI)建立sEMG与肌力的关系模型,这种方法可以同时捕获系统动な变化的特性以及系统非线性変化的特性,解決了sEMG与肌力之间的非线性和动态变化的问题。 ",
                "提出采用并行级联识别方法(PCI)建立sEMG与肌力的关系模型,这种方法可以同时捕获系统动态变化的特性以及系统非线性变化的特性,解決了SEMG与肌力之间的非线性和动态变化的问题",
              ],
            },
          ],
        },
      ],
    };
  },
  watch: {
    $route: {
      handler() {
        this.textArr00X.forEach((el, index) => {
          if (el.id == this.$route.query.id) {
            this.textArr = el.content; //内容赋值
            this.title = el.title; //标题赋值
            this.navitems[1] = el.ptitle; //导航赋值
            this.navitems[2] = el.title;
          }
        });
      },
      immediate: true,
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.detail {
  text-align: left;
  margin-top: 80px;
  height: max-content;
  margin-bottom: 20rem;
}

.ltnav {
  height: 6rem;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  font-weight: 500;
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: ">";
  }
  .breadcrumb {
    background-color: #f8f8f8;
    margin-bottom: unset;
  }
  a {
    color: #666666;
  }
}

.title {
  margin: 4rem 0px;
  span {
    font-weight: 600;
    font-size: 30px;
  }
  a {
    margin: 0px 2rem;
    color: #20afff;
    text-decoration: none;
  }
}
.pic {
  display: flex;
  margin-bottom: 8rem;
  div {
    width: 21rem;
    height: 11rem;
    margin-right: 2rem;
    background-color: #ffbfbf;
  }
}
.maintext .content {
  width: 99%;
  font-size: 18px;
  color: #77797c;
  font-weight: 400;
  line-height: 31px;
  h3 {
    font-size: 23px;
    color: #000;
    margin-bottom: 2rem;
  }
  ul {
    margin-bottom: 5rem;
  }
  li {
    position: relative;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 2rem;
    margin-bottom: 30px;
    margin-left: 20px;
  }
  li::before {
    display: block;
    background-image: url("../../assets/circle.png");
    content: "";
    position: absolute;
    left: -23px;
    top: 10px;
    width: 10px;
    height: 10px;
    transform: scale(0.7);
    background-size: cover;
  }
}
@media (max-width: 768px) {
  .ltnav {
    display: none;
  }
  .title {
    margin: 4rem 0px 0 0;
    span {
      font-size: 20px;
    }
    a {
      margin: 15px 0 !important;
      display: block;
      font-size: 12px;
    }
  }
  .pic {
    display: flex;
    margin-bottom: 4rem;
    border-top: 1px solid #ebe9e9;
    padding-top: 1.5rem;
    div {
      height: 5rem;
      margin: 0 5px;
    }
  }
  .maintext .content {
    h3 {
      font-size: 20px;
    }
    li {
    position: relative;
    font-size: 15px;
    letter-spacing: 1px;
    line-height: 1.5rem;
    margin-bottom: 30px;
    margin-left: 20px;
    color: #333333;
}
li::before{
  background-image: unset;
}
  }
}
</style>